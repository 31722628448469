.side-nav__flex-content {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column !important;
    padding: 0 !important;
    height: 100% !important;
}

a {
    font-weight: bold;
    font-size: 12px;
    text-align: start;
}

.filter-white {
    -webkit-filter: invert(1);
    /* Safari/Chrome */
    filter: invert(1);
}

.side-nav__version {
    text-align: center;
    font-size: small;
    background: #D4E7FF;
}

.side-nav__institution {
    text-align: center;
    font-size: small;
    background: #D4E7FF;
}
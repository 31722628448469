.login-bg {
    width: 20%;
}

.content-padding {
    padding-top: 0 !important;
}

.login-bg .login-image-container {

    text-align: center;
}

.login-image-container .login-image {
    width: 200px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.login-bg .forgot-password {
    display: flex;
    justify-content: right;
    color: rgb(41, 41, 239);
    text-decoration: none;
    margin-top: 5px;
    font-size: 0.6em;
}

.login-bg .forgot-password:hover {
    text-decoration: underline;
}

:root {
    background-color: rgba(210, 215, 211, 0.5);
}
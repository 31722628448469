html,
body,
#root {
    height: 100%;
    font-size: 12px;
    width: 100%;
}

@media (min-width: 1600px) {

    html,
    body,
    #root {
        font-size: 16px;
    }
}

@media (min-width: 2000px) {

    html,
    body,
    #root {
        font-size: 18px;
    }
}

.full-size {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.full-height {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.flex-align {
    display: flex;
    align-items: center;
}
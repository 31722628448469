.header__toolbar {
    display: flex;
    justify-content: space-between;
}

.header__toolbar .header__button_countdown_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
}

.header__button_countdown_container .header__countdown {
    margin-left: 3px;
}

.header__img-size {
    height: 2rem;
}

.header__account-section {
    display: flex;
    align-items: center;
    color: #EEEEEE;
}

.header__account-section .header__account-section-current-text {
    color: #EEEEEE;
}
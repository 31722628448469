.DialogContent {
    text-align: center !important;
}

.dialog-content-text {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
}

.dialog-text-fields {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.dialog-report-group {
    display: flex;
    flex-direction: column;
}

.dialog-report-single-group {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    justify-content: space-between;
    width: 100%;
}
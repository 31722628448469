.RadioActiv {
    margin-top: 1rem !important;
}

.TableCell {
    border-style: solid !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.TableRow {
    border-style: solid !important;
    border-color: var(--xsoft-blue) !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.ButtonsSaveCancel {
    margin-top: 1rem !important;
    justify-content: right !important;
}

.single-view {
    display: flex;
    flex-direction: column;
}

.single-view .single-view__text-field:nth-of-type(even) {
    margin-left: 1rem;
}

.single-view .single-view-delete-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
}

.single-view .single-view__text-field {
    padding-bottom: 1rem;
    width: 100%;
}

.single-view__form-row-width {
    width: 100%;
}

.single-view__empty-form-width {
    width: 100%;
    padding-bottom: 1rem;
}

.single-view .single-view__empty-form-width:nth-of-type(even) {
    margin-left: 1rem;
}

.single-view-tripple-values {
    display: flex;
    gap: 10px;
}

.single-view-accordion {
    margin-top: 15px;
}

.single-view-accordion-history {
    margin-top: 30px;
}

.info-label {
    font-weight: bold;
    text-align: center;
    margin-top: 14px;
    margin-bottom: 10px;
}

.single-view-person__all-container {
    display: flex;
    justify-content: space-between;
}

.single-view-person__data-container {
    width: 100%;
}

.single-view-person__text-button {
    border: none;
    background-color: inherit;
    text-align: left;
    width: 140px;
    margin-bottom: 10px;
    font-size: 20px;
    cursor: pointer;
    display: inline-block;
    color: black;
}

.single-view-person__text-button:hover {
    background: #e7e7e7;
}

.country-select {
    display: flex;
    width: 100%;
    height: 90%;
    border: none;
    border-radius: 0px;

}

.country-select button {
    background-color: #eeeeee;
    border-radius: 0px;
    outline: 0;
    border-width: 0 0 1px;
    border-color: #2d33359a;
}

.single-view-empty-container {
    height: 2rem;
}

.accordion-summary___icon-gap {
    gap: 1rem
}

.single-view__list {
    align-items: center;
    border: 1px solid black;
    width: 30%;
    background-color: #EEEEEE;
}

.single-view__list h4 {
    color: #001D53;
}

.single-view__space-between {
    justify-content: space-between;
}

.checklist-label {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.black-border {
    border: 1px solid #EEEEEE;
}

.black-border:hover {
    border: 1px solid #2D3335;
}

.grey-border {
    background-color: #EEEEEE;
    border: 1px solid #CCCCCC;
}

.grey-border:hover {
    background-color: #CCCCCC;
    border: 1px solid #EEEEEE;
}

.process-transaction {
    display: flex;
    text-align: start;
}

.single-view__list .info-text {
    font-size: 0.5em;
    text-align: center;
    color: #CC0000;
}

.error-box {
    margin-left: 5px;
    justify-content: left;
}

.error {
    font-size: 0.5em;
    text-align: center;
    color: #CC0000;
}

.home-amount-text {
    font-size: 3em;
    font-weight: bold;
    text-align: center;
    color: #007A00;
}

.announcement-container {

    display: flex;
    justify-content: space-between;
    width: 100%;
}

.announcement-container-fromuntil {
    width: 20%;
    display: flex;
    justify-content: left;
    font-style: italic;
}

.announcement-container-name {
    width: 75%;
    display: flex;
    justify-content: left;
}

.announcement-button-section {
    margin-top: 10px;
    margin-left: auto;
    margin-right: 10px;
    padding-left: 10px;
}
.announcement-button-section Button{
    margin-left: 10px;
}
.table__table-container {
    border: 3px solid #f3f3f3;
    background-color: #ffffff;
}

.TableBody tr *:last-child {
    text-align: right !important;
}

.table__table-body {
    background-color: #FFFFFF;
}

.table__table-head {
    background-color: #D4E7FF;
}

.table-overview {
    margin-bottom: 1rem;
    overflow-y: scroll;
}

.TableCellAddIcon {
    text-align: right !important;
}

.searchbar {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
}

.searchBar__input:focus {
    color: #ffffff !important;
}
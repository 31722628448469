.page-start {
    position: fixed;
    height: 4rem;
    width: 100%;
    z-index: 1;
    background-color: lightgray;
}

.page-header {
    margin-top: 3rem;
    position: fixed;
    z-index: 1;
    width: 100%;
}

.page-content {
    margin-top: 6rem;
    z-index: 0;
}
.maintenance-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    align-items: center;
    justify-content: center;
}

.maintenance-container .maintenance-img {
    margin-top: 1rem;
    size-adjust: 0.6;
}

.maintenance-container .maintenance-h1 {
    margin-top: 1rem;
    font-size: 25px;
    font-weight: bold;
}

.maintenance-container .maintenance-h2 {
    margin-top: 1rem;
    font-size: 15px;
    font-weight: bold;

}

.maintenance-container .maintenance-h4 {
    margin-top: 1rem;
    font-size: 10px;
}